import * as React from 'react';
import * as propz from 'propz';
import * as Moment from 'moment';
import { SchoolInvite } from 'Src/models/invite';
import { AppUser } from 'Src/views/App/App';
import { Button } from 'Src/components/Button/Button';
import { getTournamentLocation } from 'Src/helpers/invite/invite';
import { AcceptTournamentInviteHeader } from '../AcceptTournamentInviteHeader/AcceptTournamentInviteHeader';
import { isIndividualTournament } from 'Src/helpers/tournament/tournament';
import { AddTeamsForm } from '../AddTeamsForm/AddTeamsForm';

interface Props {
  invite: SchoolInvite;
  user: AppUser;
  onCancel: () => void;
  onAccept: (data?) => void;
}

export function AcceptTournamentInviteWithAutoEnrollmentTeams(props: Props) {
  const { onCancel, onAccept, invite, user } = props;
  console.log('Component Props:', props);

  const { activeSchoolId } = user;
  const { tournament, inviterSchool } = invite;
  console.log('Tournament Details:', tournament);

  const tournamentLocation = getTournamentLocation(invite, activeSchoolId);
  const tournamentSportName = propz.get<string>(tournament, ['sport', 'name'], '');
  const tournamentName = propz.get<string>(tournament, ['name'], '');
  const tournamentStartTime = propz.get<string>(tournament, ['startTime']);
  const tournamentStartTimeFormatted = tournamentStartTime
    ? Moment(tournament.startTime).format('DD.MM.YYYY / HH:mm')
    : '';

  const inviterSchoolName = propz.get<string>(inviterSchool, ['name'], '');

  const isIndividual = isIndividualTournament(tournament);
  console.log('Is Individual Tournament:', isIndividual);

  const { autoEnrollmentTeamsSettings } = tournament;

  let isAllGenderAndAgeTeamPlacesBooked;

  if (isIndividual) {
    //in individual tournament school can have only one team for all ages
    const [autoEnrollmentTeamsSetting] = autoEnrollmentTeamsSettings;
    const { maximumNumberTeams, currentNumberTeams } = autoEnrollmentTeamsSetting;
    console.log('Individual Tournament Team Availability:', {
      maximumNumberTeams,
      currentNumberTeams
    });
    isAllGenderAndAgeTeamPlacesBooked = maximumNumberTeams === currentNumberTeams && maximumNumberTeams !== 0;
  } else {
    isAllGenderAndAgeTeamPlacesBooked = autoEnrollmentTeamsSettings.every(autoEnrollmentTeamsSetting => {
      const { maximumNumberTeams, currentNumberTeams } = autoEnrollmentTeamsSetting;
      console.log('Team Availability for Setting:', {
        maximumNumberTeams,
        currentNumberTeams
      });
      return maximumNumberTeams === currentNumberTeams && maximumNumberTeams !== 0;
    });
  }

  const isAutoEnrollmentToWaitingList = propz.get<boolean>(tournament, ['isAutoEnrollmentToWaitingList'], false);
  console.log('Decision Variables:', {
    isAllGenderAndAgeTeamPlacesBooked,
    isAutoEnrollmentToWaitingList
  });
  const isShowWaitingListMessage = isAllGenderAndAgeTeamPlacesBooked || isAutoEnrollmentToWaitingList;
  const isShowAddTeamsForm = !isAllGenderAndAgeTeamPlacesBooked && !isIndividual && !isAutoEnrollmentToWaitingList;

  const buttonText = isAllGenderAndAgeTeamPlacesBooked ? 'Accept (waiting list)' : 'Book spaces';
  console.log('Render Decisions:', {
    isShowWaitingListMessage,
    isShowAddTeamsForm,
    buttonText
  });
  return (
    <div>
      <AcceptTournamentInviteHeader
        tournamentName={tournamentName}
        tournamentLocation={tournamentLocation}
        tournamentStartTimeFormatted={tournamentStartTimeFormatted}
        inviterSchoolName={inviterSchoolName}
        tournamentSportName={tournamentSportName}
      />

      {isShowAddTeamsForm && (
        <>
          <div>Please specify the teams:</div>
          <AddTeamsForm tournament={tournament} onSubmit={onAccept} onCancel={onCancel} />
        </>
      )}

      {isShowWaitingListMessage && (
        <div>
          Unfortunately, the {tournamentName} is completely booked. You can accept the invitation and put your school on
          waiting list
        </div>
      )}

      {!isShowAddTeamsForm && (
        <>
          <Button text={'Cancel'} onClick={onCancel} customClass={'btn btn-secondary mr-3 mt-3 mb-3'} />
          <Button text={buttonText} onClick={() => onAccept()} customClass={'mt-3 mb-3'} />
        </>
      )}
    </div>
  );
}
